<template>
  <v-card>
    <v-card-title>
      <h3>View {{ ucFirst(assessment.type) }}</h3>
    </v-card-title>
    <v-card-subtitle>
      <small>{{
        `GRADE ${subject.gradeLevel} | ${subject.name.toUpperCase()}`
      }}</small>
      <h3>{{ assessment.offline ? `Access Code : ${assessment.accessCode}` : '' }}</h3>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text>
      <v-textarea
        readonly
        :value="assessment.title"
        dense
        label="Title : "
        outlined
        auto-grow
        rows="1"
      />
      <v-textarea
        readonly
        :value="assessment.lesson"
        dense
        label="Lesson : "
        outlined
        auto-grow
      />
      <v-row>
        <v-col cols="4" sm="2">
          <v-text-field
            readonly
            :value="assessment.quarter"
            dense
            label="Quarter : "
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="4" sm="2">
          <v-text-field
            readonly
            :value="assessment.attempts"
            dense
            label="Attempts : "
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="4" sm="2">
          <v-text-field
            readonly
            :value="`${assessment.duration} minute${
              assessment.duration > 1 ? 's' : ''
            }`"
            dense
            label="Duration : "
            outlined
            hide-details
          />
        </v-col>
        <v-col v-show="assessment.attempts > 1" cols="12" sm="6">
          <v-text-field
            readonly
            :value="assessment.recordHighest ? 'Highest Score' : 'Last Attempt'"
            dense
            label="Accept : "
            outlined
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="3">
          <v-text-field
            readonly
            :value="assessment.status ? 'Active' : 'Inactive'"
            dense
            label="Status : "
            outlined
            hide-details
            :background-color="`${
              assessment.status ? 'green' : 'red'
            } lighten-4`"
          >
            <template slot="append">
              <v-icon size="20"
                >mdi-{{ assessment.status ? "check" : "block-helper" }}</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="3">
          <v-text-field
            readonly
            :value="assessment.recorded ? 'Recorded' : 'Unrecorded'"
            dense
            outlined
            hide-details
            :background-color="`${
              assessment.recorded ? 'blue' : 'pink'
            } lighten-4`"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-show="assessment.performanceTask" cols="12" sm="6">
          <v-text-field
            readonly
            value="Performance Task"
            dense
            outlined
            hide-details
          >
            <template slot="append">
              <v-icon size="20">mdi-check</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col v-show="assessment.writtenWork" cols="12" sm="6">
          <v-text-field
            readonly
            value="Written Work"
            dense
            outlined
            hide-details
          >
            <template slot="append">
              <v-icon size="20">mdi-check</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-show="assessment.from && assessment.to" cols="12">
          <p>Schedule</p>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                readonly
                :value="assessment.from"
                dense
                label="From : "
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                readonly
                :value="assessment.to"
                dense
                label="To : "
                outlined
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card
        class="mt-5"
        elevation="4"
        v-for="(test, testIndex) in assessment.assessmentTests"
        :key="testIndex"
      >
        <v-card-title>
          <v-row class="pl-3 py-2" align="start" justify="space-between">
            <h5>Test {{ testIndex + 1 }}</h5>
            <v-item-group>
              <v-btn
                @click="test.expand = !test.expand"
                text
                :small="$vuetify.breakpoint.smAndUp ? true : false"
                :x-small="$vuetify.breakpoint.xsOnly ? true : false"
                :ripple="false"
              >
                <v-icon :size="$vuetify.breakpoint.smAndUp ? '25' : '20'">
                  mdi-window-{{ test.expand ? "minimize" : "restore" }}
                </v-icon>
              </v-btn>
            </v-item-group>
          </v-row>
        </v-card-title>
        <v-card-subtitle>
          {{ test.test ? testType(test.method, test.test) : "" }}
        </v-card-subtitle>
        <v-expand-transition>
          <div v-show="test.expand">
            <v-divider></v-divider>
            <v-card-text>
              <v-textarea
                readonly
                :value="test.instruction"
                dense
                label="Instruction : "
                outlined
                rows="2"
                auto-grow
              />
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    readonly
                    :value="test.totalItems"
                    dense
                    label="Number of Items : "
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row justify="start">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        readonly
                        value="Random"
                        dense
                        outlined
                        hide-details
                        :background-color="`${
                          test.random ? 'green' : 'red'
                        } lighten-4`"
                      >
                        <template slot="append">
                          <v-icon size="20"
                            >mdi-{{
                              test.random ? "check" : "block-helper"
                            }}</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      v-show="test.test === 'multiplechoice'"
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        readonly
                        value="Image as Choices"
                        dense
                        outlined
                        hide-details
                        :background-color="`${
                          test.imageChoices ? 'green' : 'red'
                        } lighten-4`"
                      >
                        <template slot="append">
                          <v-icon size="20"
                            >mdi-{{
                              test.imageChoices ? "check" : "block-helper"
                            }}</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      v-show="
                        test.test !== 'multiplechoice' &&
                        test.test !== 'matchingtype' &&
                        test.test !== 'trueorfalse'
                      "
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        readonly
                        value="Case Sensitive"
                        dense
                        outlined
                        hide-details
                        :background-color="`${
                          test.caseSensitivity ? 'green' : 'red'
                        } lighten-4`"
                      >
                        <template slot="append">
                          <v-icon size="20"
                            >mdi-{{
                              test.caseSensitivity ? "check" : "block-helper"
                            }}</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-show="test.questions.length > 0 && test.test">
                <v-col
                  cols="12"
                  v-for="(question, questionIndex) in test.questions"
                  :key="questionIndex"
                >
                  <v-card outlined>
                    <v-card-subtitle>
                      <v-row class="py-2 px-3 mb-n7" justify="space-between">
                        <h4 class="py-2">
                          {{ `Question #${questionIndex + 1}` }}
                        </h4>
                        <v-text-field
                          readonly
                          style="max-width: 90px"
                          :value="question.points"
                          :label="`Point${question.points > 1 ? 's' : ''} : `"
                          dense
                          outlined
                        />
                      </v-row>
                    </v-card-subtitle>
                    <v-card-text>
                      <v-textarea
                        readonly
                        :value="question.question"
                        dense
                        label="Question : "
                        outlined
                        auto-grow
                        rows="1"
                      >
                        <template
                          :slot="test.test === 'enumeration' ? 'prepend' : ''"
                        >
                          <v-text-field
                            readonly
                            class="my-n2"
                            style="max-width: 115px"
                            :value="question.enumerateCount"
                            label="Enumerate : "
                            dense
                            outlined
                          />
                        </template>
                      </v-textarea>
                      <v-card v-show="question.preview" class="mb-3" outlined>
                        <v-card-subtitle>
                          <v-row class="pa-3" justify="space-between">
                            Image Preview :
                          </v-row>
                          <v-img
                            :src="question.preview"
                            class="mt-3"
                            max-height="500"
                            contain
                          />
                        </v-card-subtitle>
                      </v-card>
                      <div v-show="test.method === 'AUTOMATIC'">
                        <div
                          v-if="
                            test.test === 'matchingtype' ||
                            test.test === 'trueorfalse'
                          "
                        >
                          <v-text-field
                            v-if="test.test === 'trueorfalse'"
                            readonly
                            class="mb-n5"
                            :value="
                              assessment.assessmentTests[testIndex].questions[
                                questionIndex
                              ].answers[0].answer.toUpperCase()
                            "
                            dense
                            label="Answer : "
                            outlined
                          />
                          <v-textarea
                            v-else
                            readonly
                            class="mb-n5"
                            :value="
                              assessment.assessmentTests[testIndex].questions[
                                questionIndex
                              ].answers[0].answer
                            "
                            dense
                            label="Answer : "
                            outlined
                            auto-grow
                            rows="1"
                          />
                        </div>
                        <v-card v-else outlined>
                          <v-card-subtitle>
                            <v-row
                              class="pa-4"
                              align="center"
                              justify="space-between"
                            >
                              {{
                                test.test === "multiplechoice"
                                  ? "Choices"
                                  : "Answers"
                              }}
                            </v-row>
                          </v-card-subtitle>
                          <v-card-text>
                            <v-row
                              v-if="test.test === 'multiplechoice'"
                              align="center"
                              justify="start"
                            >
                              <v-col
                                cols="12"
                                sm="4"
                                v-for="(
                                  choice, choiceIndex
                                ) in question.choices"
                                :key="choiceIndex"
                              >
                                <v-textarea
                                  v-if="!test.imageChoices"
                                  readonly
                                  class="mb-n5"
                                  :value="choice.choice"
                                  :background-color="
                                    choice.correct ? 'green lighten-4' : ''
                                  "
                                  :hint="choice.correct ? 'Correct Answer' : ''"
                                  persistent-hint
                                  dense
                                  outlined
                                  auto-grow
                                  rows="1"
                                >
                                  <template slot="prepend-inner">
                                    <v-icon size="25"
                                      >mdi-alpha-{{
                                        intToChar(choiceIndex)
                                      }}</v-icon
                                    >
                                  </template>
                                </v-textarea>
                                <v-row v-else align="start">
                                  <v-col cols="12">
                                    <v-card
                                      class="mb-3"
                                      :style="
                                        choice.correct
                                          ? 'border: thin solid #1b5e20;'
                                          : ''
                                      "
                                      outlined
                                    >
                                      <v-card-subtitle>
                                        <v-row
                                          class="pa-3"
                                          justify="space-between"
                                        >
                                          <v-icon size="25"
                                            >mdi-alpha-{{
                                              intToChar(choiceIndex)
                                            }}</v-icon
                                          >
                                          <v-btn
                                            v-show="choice.correct"
                                            style="
                                              background-color: #1b5e20 !important;
                                              color: #fff !important;
                                            "
                                            absolute
                                            right
                                            shaped
                                            x-small
                                            disabled
                                            text
                                          >
                                            Correct
                                          </v-btn>
                                        </v-row>
                                        <v-skeleton-loader
                                          v-if="!choice.preview"
                                          class="mt-3"
                                          boilerplate
                                          type="image"
                                          max-height="150"
                                          max-width="250"
                                        ></v-skeleton-loader>
                                        <v-img
                                          v-else
                                          :src="choice.preview"
                                          class="mt-3"
                                          max-height="150"
                                          contain
                                        />
                                      </v-card-subtitle>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row v-else>
                              <v-col
                                v-for="(
                                  answer, answerIndex
                                ) in question.answers"
                                :key="answerIndex"
                                cols="12"
                              >
                                <v-textarea
                                  readonly
                                  class="mb-n5"
                                  :value="answer.answer"
                                  dense
                                  :label="`Answer #${answerIndex + 1} : `"
                                  outlined
                                  auto-grow
                                  rows="1"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
import CONSTANTS from "@/config/constants";
import notify from "@/utilities/notifications";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "AssessmentView",
  data() {
    return {
      testOptions: CONSTANTS.TEST_TYPES,
      notify: notify,
      assessment: {
        type: "",
        title: "",
        lesson: "",
        attempts: "",
        duration: 0,
        status: true,
        recorded: true,
        performanceTask: false,
        writtenWork: false,
        recordHighest: false,
        quarter: "",
        subjectId: "",
        from: null,
        to: null,
        assessmentTests: [
          {
            instruction: "",
            test: "",
            random: false,
            caseSensitivity: false,
            imageChoices: false,
            totalItems: "",
            questions: [],
            method: "AUTOMATIC",
            expand: true,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      subject: (state) => state.subjectsModule.subject,
      assessmentData: (state) => state.assessmentModule.assessment,
    }),
    assessmentId() {
      return this.$route.query.id;
    },
  },
  async mounted() {
    const getAssessment = await this.$store.dispatch(
      "assessmentModule/getAssessment",
      this.assessmentId
    );

    if (this.assessmentId && getAssessment) {
      this.assessment = this.setupAssessment(this.assessmentData);
    } else {
      notify({
        title: "Info",
        status: "info",
        message: "Failed to fetch assessment data, please try again",
      });
      this.$router.push({
        name: "Assessment",
      });
    }

    await this.mapImages(this.assessmentData);
  },
  methods: {
    moment: moment,
    async mapImages(assessment) {
      let tests = [...assessment.assessmentTests];
      tests.map((test, testIndex) => {
        let questions = [...test.questions];
        questions.map(async (question, questionIndex) => {
          if (question.image) {
            this.assessment.assessmentTests[testIndex].questions[
              questionIndex
            ].preview = await this.$store.dispatch(
              "assessmentModule/getFileUrl",
              question.image
            );
          } else {
            this.assessment.assessmentTests[testIndex].questions[
              questionIndex
            ].preview = null;
          }

          let choices = [...question.choices];
          if (test.imageChoices) {
            choices.map(async (choice, choiceIndex) => {
              this.assessment.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].preview = await this.$store.dispatch(
                "assessmentModule/getFileUrl",
                choice.choice
              );
            });
          } else {
            choices.map(async (choice, choiceIndex) => {
              this.assessment.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].preview = null;
            });
          }
        });
      });
    },
    sanitizeDate(date) {
      let dateTime = null;
      if (date) {
        let sanitizedDate = moment(date).format("YYYY-MM-DD hh:mm A");

        dateTime = sanitizedDate;
      }

      return dateTime;
    },
    setupAssessment(assessmentData) {
      let assessment = {
        ...assessmentData,
        from: this.sanitizeDate(assessmentData.from),
        to: this.sanitizeDate(assessmentData.to),
      };
      delete assessment.teacher;
      delete assessment.createdAt;

      let tests = assessment.assessmentTests?.map((test) => {
        let method = this.testOptions["AUTOMATIC"].find(
          (type) => type.value == test.test
        )
          ? "AUTOMATIC"
          : "MANUAL";
        let questions = test.questions.map((question) => {
          let preview = null,
            answers = [{ answer: "" }],
            choices = [
              {
                choice: "",
                correct: true,
                preview: null,
              },
              {
                choice: "",
                correct: false,
                preview: null,
              },
              {
                choice: "",
                correct: false,
                preview: null,
              },
            ];

          if (method === "AUTOMATIC") {
            if (test.test === "multiplechoice") {
              choices = question.choices.map((choice) => {
                let preview = null;

                choice = { ...choice, preview };
                return choice;
              });
            } else {
              answers = question.answers;
            }
          }

          question = {
            ...question,
            preview,
            answers,
            choices,
          };

          return question;
        });

        test = {
          ...test,
          totalItems: questions.length,
          questions,
          method,
          expand: true,
        };
        return test;
      });

      return { ...assessment, assessmentTests: tests };
    },
    intToChar(int) {
      const code = "a".charCodeAt(0);

      return String.fromCharCode(code + int);
    },
    testType(method, value) {
      let type = this.testOptions[method].find((type) => type.value == value);
      return type.text;
    },
    ucFirst(value) {
      return value.charAt(0).toUpperCase() + value.substring(1);
    },
  },
};
</script>
